<template>
  <div class="w-full flex flex-col">
    <loader :loading="loading" :backdrop="true"/>
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-2 text-2xl mb-2">Paquetería</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">
      Editando Paquete "{{packageInfo.description}}" de {{packageInfo.client}} GV-{{packageInfo.id}}
    </h4>
     <section class="w-full flex flex-col px-2 py-3 bg-white">
      <div class="header">
        <h3 class="text-start font-normal text-sm">Usuarios</h3>
        <div class="flex">
          <div class="flex p-0.5 mr-1 bg-green-500 rounded self-center">
            <icon
              @click.native="editPackage"
              class="text-white" name="FlatCheckIcon" />
          </div>
          <div class="flex p-0.5 bg-blue-500  rounded self-center">
            <icon
              @click.native="isModalNotesOpen = true"
              class="text-white" name="DocumentTextIcon" />
          </div>
          <div class="flex p-0.5 bg-gray-300 ml-1 rounded self-center">
            <icon
              @click.native="() => $router.push({ name: 'Paquete' })"
              class="text-white" name="XIcon" />
          </div>
        </div>
      </div>
      <div class="w-full mt-2">
        <b-card class="nav-tabs-custom" no-body>
          <b-tabs
            v-model="tabIndex"
            small
            card>
            <b-tab title="Paquete" active :title-link-class="linkClass(0)">
              <div class="flex grid grid-cols-2">
        <input-field-with-icon
          prepend="#"
          idInput="tracking"
          idFormGroup="tracking-group"
          type="text"
          label="Tracking"
          v-model="packageInfo.tracking"
          :disabled="true"
        />
        <select-input
          class="ml-2 w-full"
          idInput="carrier-input"
          idFormGroup="carrier-group"
          label="Transportista"
          v-model="packageInfo.carrier_id"
          :options="carrierOptions"
        />
      </div>
      <div class="flex grid grid-cols-1 my-2">
        <input-field
          isTextArea
          idInput="descriptionInput"
          idFormGroup="description-group"
          type="text"
          label="Descripción"
          v-model="packageInfo.description"
        />
      </div>
      <div class="flex grid grid-cols-2 my-2">
        <select-input
          class="mr-1 w-full"
          idInput="client-input"
          idFormGroup="client-group"
          label="Cliente"
          v-model="packageInfo.client"
          :options="clientOptions"
        />
        <select-input
          class="ml-1 w-full"
          idInput="store-input"
          idFormGroup="store-group"
          label="Remitente (Tienda)"
          v-model="packageInfo.store"
          :options="storeOptions"
        />
      </div>
      <div class="flex grid grid-cols-2 my-2">
        <div class="flex grid grid-cols-3 mr-2">
          <input-field-with-icon
            class="w-full"
            append="cm"
            idInput="large"
            idFormGroup="large-group"
            type="number"
            label="Largo"
            v-model="packageInfo.length"
          />
          <input-field-with-icon
            append="cm"
            class="w-4/5 mx-1"
            idInput="width"
            idFormGroup="width-group"
            type="number"
            label="Ancho"
            v-model="packageInfo.width"
          />
          <input-field-with-icon
            class="ml-2"
            append="cm"
            idInput="high"
            idFormGroup="high-group"
            type="text"
            label="Alto"
            v-model="packageInfo.height"
          />
        </div>
        <div class="flex grid grid-cols-2 ml-2">
          <input-field-with-icon
            append="lbs"
            idInput="volumetricWeight"
            idFormGroup="volumetricWeight-group"
            type="number"
            label="Peso Volumétrico"
            v-model="packageInfo.volumetric_weight"
          />
          <input-field-with-icon
            append="lbs"
            class="ml-2"
            idInput="weight"
            idFormGroup="weight-group"
            type="number"
            label="Peso"
            v-model="packageInfo.weight"
          />
        </div>
      </div>
      <div class="flex grid grid-cols-2 my-2">
        <input-field
          class="text-sm"
          idInput="amount"
          idFormGroup="lastName-group"
          type="number"
          label="Valor"
          v-model="packageInfo.value"
        />
        <input-field
          class="text-sm ml-2 w-full"
          idInput="pieces"
          idFormGroup="lastName-group"
          type="number"
          label="Piezas"
          v-model="packageInfo.pieces"
        />
        <select-input
          class="w-full"
          idInput="packaging-input"
          idFormGroup="packaging-group"
          label="Embalaje"
          v-model="packageInfo.packing_id"
          :options="packagingOptions"
        />
        <radio-buttons
          class="ml-4 mt-1 w-full"
          idInput="actionRequired-input"
          idFormGroup="actionRequired-group"
          label="Acción requerida"
          :options="actionOptions"
          v-model="packageInfo.action_required"
        />
      </div>
            </b-tab>
            <b-tab title="Historial" :title-link-class="linkClass(1)">
                <table-event-logs
                :key="reloadTable"
                :eventLogs="packageInfo.event_logs"
                @showInfo="openModal"/>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </section>
    <Modal v-show="isModalOpen"  :size="'lg'" @close="isModalOpen = !isModalOpen">
       <template v-slot:header>
        <div>
          <h1 class="text-gray-600 font-bold text-3xl mb-8">
            Detalles
          </h1>
        </div>
      </template>
      <template v-slot:body>
        <div>
            <table-event-log-detail
                :details="[]"/>
          <div class="flex justify-between mt-8">
            <button
              @click="isModalOpen = !isModalOpen"
              class="flex p-2 mr-2 bg-gray-400 ml-1 rounded self-center">
             <span class="text-white">Cancelar</span>
            </button>
            <button class="flex p-2 mr-1 bg-blue-500 rounded self-center">
              <span class="text-white">Aceptar</span>
            </button>
          </div>
        </div>
      </template>
    </Modal>
     <Modal v-show="isModalNotesOpen"  :size="'lg'" @close="isModalNotesOpen = !isModalNotesOpen">
       <template v-slot:header>
        <div>
          <h1 class="text-gray-600 font-bold text-3xl ">
            Agregar Nota al Cliente
          </h1>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <input-field
              isTextArea
              idInput="descriptionInput"
              idFormGroup="description-group"
              type="text"
              label="Notas"
            />
          <div class="flex justify-between mt-8">
            <div>
              <button
                  @click="isModalNotesOpen = !isModalNotesOpen"
                  class="flex p-2 mr-2 bg-gray-400 ml-1 rounded self-center">
                <span class="text-white">Cancelar</span>
              </button>
            </div>
            <div class="flex justify-between">
               <button class="flex p-2 mr-1 bg-blue-500 rounded self-center">
              <span class="text-white">Guardar</span>
            </button>
             <button class="flex p-2 ml-1 bg-blue-500 rounded self-center">
              <span class="text-white">Guardar y notificar</span>
            </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Icon from '@/components/Icon.vue';
import UserMixin from '@/mixins/UserMixin';
import Loader from '@/components/Loader.vue';
import InputFieldWithIcon from '@/components/InputFieldWithIcon.vue';
import SelectInput from '@/components/SelectInput.vue';
import InputField from '@/components/InputField.vue';
import RadioButtons from '@/components/RadioButtons.vue';
import TableEventLogs from '@/components/TableEventLogs.vue';
import TableEventLogDetail from '@/components/TableEventLogDetail.vue';
import Modal from '@/components/Modal.vue';
import request from '@/api/request-services';

export default {
  mixins: [UserMixin],
  components: {
    BreadCrumbs,
    Icon,
    Loader,
    InputFieldWithIcon,
    SelectInput,
    InputField,
    RadioButtons,
    TableEventLogs,
    Modal,
    TableEventLogDetail,
  },
  data() {
    return {
      loading: false,
      routes: [],
      tabIndex: 0,
      reloadTable: false,
      isModalOpen: false,
      isModalNotesOpen: false,
      packageInfo: {
        tracking: '',
        carrier_id: null,
        description: '',
        client: null,
        store: null,
        volumetric_weight: null,
        length: null,
        weight: null,
        height: null,
        action_required: 0,
        value: null,
        packing_id: null,
        event_logs: [],
      },
      carrierOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Correos de Costa Rica', value: 4 },
        { text: 'UPS', value: 1 },
        { text: 'FEDEX', value: 2 },
      ],
      clientOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Alejandro Gomzalez', value: 'alejandro_gonzalez' },
      ],
      storeOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'GV Express', value: 'gv_express' },
      ],
      packagingOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Box', value: 1 },
      ],
      actionOptions: [
        { text: 'No', value: 0 },
        { text: 'Si', value: 1 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute?.name !== current.name) this.routes.push(current);
    },
    async handlePackageInfo() {
      this.loading = true;
      const response = await request.requestGET(`packages/${this.$route.params.id}`, this.getToken);
      if (response.status === 200) {
        this.packageInfo = response.data;
        this.loading = false;
        this.reloadTable = true;
      } else {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'No se pudo cargar la información del paquete, por favor intenta de nuevo',
        });
        this.loading = false;
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['border-t border-red-500', 'text-dark'];
      }
      return ['bg-light', 'text-dark'];
    },
    async editPackage() {
      this.loading = true;
      const data = {
        carrier_id: this.packageInfo.carrier_id,
        description: this.packageInfo.description,
      };
      const response = await request.requestPUT(`packages/${this.$route.params.id}`, data, this.getToken);
      if (response.status === 200) {
        this.$notify({
          type: 'success',
          title: 'Correcto',
          text: 'El paquete ha sido editado',
        });
        this.loading = false;
      } else {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'El paquete no ha sido editado, por favor intenta de nuevo',
        });
        this.loading = false;
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
    this.handlePackageInfo();
  },
};
</script>

<style scoped>
 .header {
    @apply flex justify-between p-1 border-b border-gray-300;
  }
 .nav-tabs-custom {
    margin-bottom: -0.5rem !important;
  }
</style>
