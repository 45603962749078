<template>
  <div class="w-full flex flex-col">
    <loader :loading="loading" :backdrop="true"/>
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-2xl mb-2">Paquetes</h3>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-5 gap-4 mt-4">
              <input-field
                idInput="keyWord"
                idFormGroup="keyWord-group"
                type="text"
                label="Palabra Clave"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
              <select-input
                class="mx-2 w-full"
                idInput="filterByInput"
                idFormGroup="filterBy-group"
                label="Filtrar por"
                v-model="filterForm.filterBySelect"
                :options="filterListOptions"
              />
              <select-input
                class="w-full"
                idInput="operator"
                idFormGroup="operator-group"
                label="Operador"
                v-model="filterForm.operatorSelect"
                :options="operatorListOptions"
              />
              <input-field
                class="mx-2"
                idInput="from-date"
                idFormGroup="fromDate-group"
                type="date"
                label="Desde"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.from"
              />
              <input-field
              idInput="to-date"
              idFormGroup="toDate-group"
              type="date"
              label="Hasta"
              classLabel="mt-1"
              classInput="input-filter"
              v-model="filterForm.to"
            />
            </div>
            <div class="flex grid grid-cols-4 gap-4 mt-4" v-if="isToggle">
            <select-input
              idInput="clientSelect"
              idFormGroup="client-group"
              label="Cliente"
              v-model="filterForm.client"
              :options="clientListOptions"
            />
            <select-input
              idInput="sortBySelect"
              idFormGroup="sort-group"
              label="Estado"
              v-model="filterForm.state"
              :options="statuses"
              :valueField="'id'"
              :textField="'display_name'"
            />
            <select-input
              idInput="sortBySelect"
              idFormGroup="sort-group"
              label="Pago"
              v-model="filterForm.payment"
              :options="payments"
              :valueField="'id'"
              :textField="'display_name'"
            />
            <select-input
              idInput="sortBySelect"
              idFormGroup="sort-group"
              label="Ordenar por"
              v-model="filterForm.sortBy"
              :options="sortByListOptions"
            />
            <select-input
              idInput="sortWaySelect"
              idFormGroup="sortWay-group"
              label="Ordenar de forma"
              v-model="filterForm.agent"
              :options="sortWayListOptions"
            />
            <select-input
              class="focus:outline-none"
              idInput="officeSelect"
              idFormGroup="office-group"
              label="Sucursal"
              v-model="filterForm.office"
              :options="agentListOptions"
            />
            </div>
             <div>
            <button
              @click="cleanFilters"
              class="bg-red-700 rounded p-2 text-center mt-6 cursor-pointer w-3/12 mr-2"
              type="button">
              <icon
                class="text-white inline"
                name="XIcon" />
              <p  class="text-white inline ml-1">Limpiar</p>
            </button>
             <button
              @click="loadPackages"
              class="bg-blue-700 rounded p-2 text-center mt-6 cursor-pointer w-3/12"
              type="button">
              <icon
                class="text-white inline"
                name="FilterIcon" />
              <p  class="text-white inline ml-1">Filtrar</p>
            </button>
          </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-start">
        <label class="mb-4">Paqueteria</label>
      </div>
      <table-package
      :packages="tableData"
      :statusList="statuses"
      @onChangePage="loadPackages"/>
    </section>
  </div>
</template>
<script>
import { BForm } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TablePackage from '@/components/TablePackage.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Loader from '@/components/Loader.vue';
import Icon from '@/components/Icon.vue';

import request from '@/api/request-services';
import PackageMixin from '@/mixins/PackageMixin';

export default {
  name: 'Package',
  mixins: [PackageMixin],
  components: {
    BForm,
    TablePackage,
    PlusIcon,
    MinusIcon,
    InputField,
    SelectInput,
    BreadCrumbs,
    Icon,
    Loader,
  },
  data() {
    return {
      loading: false,
      isToggle: false,
      filterForm: {
        keyWord: '',
        filterBySelect: null,
        operatorSelect: null,
        client: [],
        state: [],
        payment: null,
        sortBy: null,
        office: [],
        from: '',
        to: '',
      },
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      filterListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'GVT', value: 'gvt' },
        { text: 'Cliente', value: 'cliente' },
        { text: 'Estado', value: 'estado' },
        { text: 'Pago', value: 'pago' },
      ],
      operatorListOptions: [
        { text: '', value: null },
      ],
      clientListOptions: [
        { text: 'Seleccione una opcion', value: [] },
        { text: 'Alejandro Gonzalez', value: 1 },
      ],
      agentListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Branch office 1', value: 1 },
      ],
      sortByListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'ID', value: 'id' },
      ],
      sortWayListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Decendiente', value: 'decendiente' },
        { text: 'Ascendiente', value: 'ascendiente' },
      ],
      // packages: [
      //   {
      //     gvt: 'GVY108430',
      //     agent: '',
      //     client: 'Manuel Garro Campos',
      //     state: 'Procesando',
      //     payment: 'Pendiente',
      //     invoice: '$13.4',
      //   },
      //   {
      //     gvt: 'GVY108430',
      //     agent: '',
      //     client: 'Manuel Garro Campos',
      //     state: 'Procesando',
      //     payment: 'Pendiente',
      //     invoice: '$13.4',
      //   },
      //   {
      //     gvt: 'GVY108430',
      //     agent: '',
      //     client: 'Manuel Garro Campos',
      //     state: 'Procesando',
      //     payment: 'Pendiente',
      //     invoice: '$13.4',
      //   },
      //   {
      //     gvt: 'GVY108430',
      //     agent: '',
      //     client: 'Manuel Garro Campos',
      //     state: 'Procesando',
      //     payment: 'Pendiente',
      //     invoice: '$13.4',
      //   },
      // ],
      // packages: [],
      tableData: {
        packages: [],
        links: [],
        current_page: 1,
        last_page: 1,
      },
      statuses: [],
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
    setCategoryValue() {
      //
    },
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
    async loadPackages(page = 1) {
      this.loading = true;
      const response = await request.requestGET(`packages?keyword=${this.filterForm.keyWord}&page=${page}&user_id=${this.filterForm.client}&status_id=${this.filterForm.state}&payment=${this.filterForm.payment}&branch_office=${this.filterForm.office}&start_date=${this.filterForm.from}&end_date=${this.filterForm.to}`, this.getToken);
      if (response.status === 200) {
        this.tableData.packages = response.data.packages.data;
        this.tableData.links = response.data.packages.links.slice(1, -1);
        this.tableData.current_page = response.data.packages.current_page;
        this.tableData.last_page = response.data.packages.last_page;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async loadTrackingStatus() {
      const response = await request.requestGET('status/tracking', this.getToken);
      if (response.status === 200) {
        this.statuses = response.data;
      }
    },
    async handlePayments() {
      this.loading = true;
      const response = await request.requestGET('status/payment', this.getToken);
      if (response.status === 200) {
        this.loadPayments(response.data);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async handleStates() {
      this.loading = true;
      const response = await request.requestGET('status/packages', this.getToken);
      if (response.status === 200) {
        this.loadStatuses(response.data);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    cleanFilters() {
      this.filterForm.keyWord = '';
      this.filterForm.client = [];
      this.filterForm.state = '';
      this.filterForm.office = [];
      this.filterForm.from = '';
      this.filterForm.to = '';
      this.loadPackages();
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
    this.loadPackages();
    this.handlePayments();
    this.handleStates();
    this.loadTrackingStatus();
  },
};
</script>
<style scoped>
  .page-wrap {
    overflow-x: hidden;
    font-family: 'Helvetica Neue', sans-serif;
    line-height: 26px;
  }
  .page-content {
    width: 80%;
    position: relative;
    z-index: 0;
    padding: 10px 15px;
    transition: +0.6s;
    transform: translateX(320px);
  }
</style>
