<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-2 text-2xl mb-2">Detalle del paquete</h3>
    <section class="w-full bg-white p-2 my-8">
      <div class="w-full my-2 flex justify-between">
        <h3 class="text-start mt-1 text-xl">GV-1 - Paquete</h3>
      </div>
      <div class="flex border-t pt-2 border-gray-300">
        <p class="text-sm">Tu paquete con la descripción "ACCESORIOS TECNOLÓGICOS "
          se encuentra en estado: En tránsito a Destino.</p>
      </div>
      <div>
         <div class="track">
              <div class="step active">
                <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="ExclamationCircleSolidIcon" />
                </span>
                <span class="text">Proveedor</span>
              </div>
              <div class="step active">
               <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="BuildingSolidIcon" />
                </span>
                <span class="text">Bodega MIA</span>
              </div>
              <div class="step">
               <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="PaperAirplaneSolid" />
                </span>
                <span class="text">En Tránsito</span>
              </div>
              <div class="step">
                <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="ClipboardListSolidIcon" />
                </span>
                <span class="text">Aduanas SJO</span>
              </div>
              <div class="step">
                <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="ClipboardCheckSolidIcon" />
                </span>
                <span class="text">Procesado SJO</span>
              </div>
               <div class="step">
                 <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="TruckSolidIcon" />
                </span>
                <span class="text">En Ruta</span>
              </div>
              <div class="step">
               <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="CashSolidIcon" />
                </span>
                <span class="text">Listo en Sucursal</span>
              </div>
              <div class="step">
                <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="ThumbUpSolidIcon" />
                </span>
                <span class="text">Entregado</span>
              </div>
              <div class="step">
                <span class="icon">
                  <icon
                  class="w-8 h-8 text-white inline mb-1"
                  name="BuildingSolidIcon" />
                </span>
                <span class="text">Devuelto</span>
              </div>
          </div>
      </div>
      <div>
        <div class="w-full flex grid grid-cols-2 gap-4">
          <input-field
            class="text-sm"
            idInput="name"
            idFormGroup="name-group"
            type="text"
            label="Cliente"
          />
          <input-field
            class="text-sm"
            idInput="lastName"
            idFormGroup="lastName-group"
            type="text"
            label="Descripción"
          />
          <input-field
            class="text-sm"
            idInput="companyName"
            idFormGroup="companyName-group"
            type="text"
            label="Valor"
          />
          <input-field
            class="text-sm"
            idInput="email"
            idFormGroup="email-group"
            type="email"
            label="Tracking"
          />
          <input-field
            class="text-sm"
            idInput="username"
            idFormGroup="username-group"
            type="text"
            label="Remitente"
          />
          <input-field
            class="text-sm"
            idInput="code"
            idFormGroup="code-group"
            type="text"
            label="Impuesto"
          />
          <input-field
            class="text-sm"
            idInput="idNumber"
            idFormGroup="idNumber-group"
            type="text"
            label="Embalajes"
          />
          <input-field
            class="text-sm"
            idInput="username"
            idFormGroup="username-group"
            type="text"
            label="Piezas"
          />
          <input-field
            class="text-sm"
            idInput="code"
            idFormGroup="code-group"
            type="text"
            label="Peso"
          />
          <input-field
            class="text-sm"
            idInput="idNumber"
            idFormGroup="idNumber-group"
            type="text"
            label="Peso Volumétrico"
          />
          <input-field
            class="text-sm"
            idInput="idNumber"
            idFormGroup="idNumber-group"
            type="text"
            label="Dimensiones"
          />
        </div>
      </div>
      <div>
        <div class="w-full flex grid grid-cols-2 gap-4 mt-4">
          <input-field
            class="text-sm"
            idInput="name"
            idFormGroup="name-group"
            type="text"
            label="Creado Por"
          />
          <input-field
            class="text-sm"
            idInput="lastName"
            idFormGroup="lastName-group"
            type="text"
            label="Creado"
          />
          <input-field
            class="text-sm"
            idInput="companyName"
            idFormGroup="companyName-group"
            type="text"
            label="Actualizado Por"
          />
          <input-field
            class="text-sm"
            idInput="companyName"
            idFormGroup="companyName-group"
            type="text"
            label="Actualizado"
          />
          <input-field
            class="text-sm"
            idInput="email"
            idFormGroup="email-group"
            type="email"
            label="AWB"
          />
          <input-field
            class="text-sm"
            idInput="username"
            idFormGroup="username-group"
            type="text"
            label="AWB"
          />
          <input-field
            class="text-sm"
            idInput="code"
            idFormGroup="code-group"
            type="text"
            label="Almacén"
          />
          <input-field
            class="text-sm"
            idInput="idNumber"
            idFormGroup="idNumber-group"
            type="text"
            label="Archivos Adjuntos"
          />
        </div>
      </div>
    </section>
    <section class="w-full bg-white p-2 ">
       <h3 class="text-start mt-1 text-xl">Historial</h3>
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Icon from '@/components/Icon.vue';
import InputField from '@/components/InputField.vue';
// import request from '@/api/request-services';
import UserMixin from '@/mixins/UserMixin';

export default {
  mixins: [UserMixin],
  components: {
    BreadCrumbs,
    Icon,
    InputField,
  },
  data() {
    return {
      routes: [],
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>

<style scoped>
.track {
    position: relative;
    background-color: #ddd;
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px
}

.track .step {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 25%;
    margin-top: -18px;
    text-align: center;
    position: relative
}

.track .step.active:before {
    color: #FFF;
    background: #8bc535;
}

.track .step::before {
    height: 7px;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 18px
}

.track .step.active .icon {
    background: #8bc535;
    color: #fff
}

.track .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #ddd
}

.track .step.active .text {
    font-weight: 400;
    color: #000
}

.track .text {
    display: block;
    margin-top: 7px
}
</style>
