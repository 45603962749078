<template>
  <div>
    <table class="w-full">
      <thead>
        <tr class="w-full headerTable text-center">
          <th class="th-Row">No.</th>
          <th class="th-Row">GVT</th>
          <th class="th-Row">Cliente</th>
          <th class="th-Row">Descripción</th>
          <th class="th-Row">Valor</th>
          <th class="th-Row">Adjuntos</th>
          <th class="th-Row">Categoria</th>
          <th class="th-Row">Validar</th>
        </tr>
      </thead>
      <tbody>
        <template class="my-4" >
          <!-- <tr class="rowTable my-2 text-center" v-bind:key="item.id" >
            <td class="td-Row">{{i+1}}</td>
            <td class="td-Row">{{item.gvt}}</td>
            <td class="td-Row">{{item.client}}</td>
            <td class="td-Row">
              <button
                :class="{
                  isProcessing: item.state === 'Procesando'
                }">
                {{item.state}}
              </button>
            </td>
            <td class="td-Row">
              <button
                :class="{
                  isPendent: item.state === 'Procesando'
                }">
                {{item.payment}}
              </button>
            </td>
            <td class="td-Row">
              <button
                class="edit">Editar</button></td>
            <td class="td-Row">
              <button
                :class="{
                  isSuccess: item.invoice
                }">{{item.invoice}}</button></td>
            <td class="td-Row"><vertical-dots /></td>
          </tr> -->
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};

export default {
  name: 'TablePackageValidation',
  components: {},
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    packages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
  .rowTable {
    margin-top: 20px !important;
  }
  .isProcessing {
    @apply bg-blue-700 text-white px-6 py-2 rounded-full;
  }
  .isPendent {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .delete {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .edit {
    @apply bg-blue-800 text-white px-6 py-2 rounded-full;
  }
  .isSuccess {
    @apply bg-green-500 text-white px-6 py-2 rounded-full;
  }
  .headerTable {
    background-color: var(--light-gray);
    padding: 1rem !important;
  }
  .th-Row {
    @apply text-sm;
    padding: 0.5rem !important;
    margin-bottom: 1rem;
    font-family: 'FreeSans';
  }
  .td-Row {
    @apply border-t border-gray-200 text-sm;
    padding: 1.5rem !important;
    font-family: 'FreeSans';
  }
  ol, ul {
      padding-left: 2rem !important;
  }
</style>
