export default {
  methods: {
    packageStatus(id) {
      let packageStatus = '';
      switch (id) {
        case 1:
          packageStatus = 'En Espera';
          break;
        case 2:
          packageStatus = 'Recibido en Origen';
          break;
        case 3:
          packageStatus = 'En tránsito a Destino';
          break;
        case 4:
          packageStatus = 'Aduanas en Destino';
          break;
        case 5:
          packageStatus = 'Procesado';
          break;
        case 6:
          packageStatus = 'En Ruta';
          break;
        case 7:
          packageStatus = 'Listo en Sucursal';
          break;
        case 8:
          packageStatus = 'Entregado';
          break;
        case 9:
          packageStatus = 'Rechazado';
          break;
        case 10:
          packageStatus = 'Devuelto';
          break;
        case 11:
          packageStatus = 'Proceso en Aduanas';
          break;
        case 12:
          packageStatus = 'Prioridad';
          break;
        case 13:
          packageStatus = 'Pendiente Encomienda';
          break;
        case 14:
          packageStatus = 'Encomienda';
          break;
        case 15:
          packageStatus = 'Desconocido';
          break;
        case 16:
          packageStatus = 'Faltan Datos';
          break;
        case 17:
          packageStatus = 'Rechazo en ruta';
          break;
        default:
          packageStatus = 'En Espera';
      }
      return packageStatus;
    },
  },
};
