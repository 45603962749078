<template>
  <div class="scroll">
    <!-- <div  v-if="eventLogs.lastPage > 1" class="w-2/12">
      <ul  class="pagination">
        <li v-if="eventLogs.currentPage > 1">
          <button @click="onChangePage(eventLogs.currentPage - 1)">
             <ChevronLeftIcon/>
          </button>
        </li>
        <li
          v-for="(link, i) in eventLogs.links"
          :key="i"
          :class="{'active': link.label == eventLogs.currentPage}"
          @click="link.label != '...' ? onChangePage(link.label) : {}">
          <button>{{link.label}}</button>
        </li>
        <li v-if="eventLogs.currentPage < eventLogs.lastPage">
          <button @click="onChangePage(eventLogs.currentPage + 1)">
            <ChevronRightIcon/>
          </button>
        </li>
      </ul>
    </div> -->
    <table class="w-full">
      <thead>
        <tr class="w-full headerTable text-center">
          <th class="th-Row">Fecha & Hora</th>
          <th class="th-Row">Realizado por</th>
          <th class="th-Row">Estado</th>
          <th class="th-Row">Notas</th>
          <th class="th-Row">Más detalles</th>
        </tr>
      </thead>
      <tbody>
        <template class="my-4" v-for="item in eventLogs">
          <tr class="rowTable my-2 text-center" v-bind:key="item.id" >
            <td class="td-Row">{{formatDateTime(item.created_at)}}</td>
            <td class="td-Row">
             {{item.user.email}}
            </td>
            <td class="td-Row">
             {{item.status.display_name}}
            </td>
            <td class="td-Row">
             <b v-if="item.notes">{{item.notes}}</b>
             <b v-else>N/A</b>
            </td>
            <td class="td-Row">
              <button
                @click="openModal(item.id)"
                class="px-2 py-2 text-white self-center rounded bg-blue-600">
                <icon class="text-center m-auto" name="ExclamationCircleSolidIcon" />
              </button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <!-- <div  v-if="eventLogs.lastPage > 1" class="w-2/12 mt-2">
      <ul  class="pagination">
        <li v-if="eventLogs.currentPage > 1">
          <button @click="onChangePage(eventLogs.currentPage - 1)">
             <ChevronLeftIcon/>
          </button>
        </li>
        <li
          v-for="(link, i) in eventLogs.links"
          :key="i"
          :class="{'active': link.label == eventLogs.currentPage}"
          @click="link.label != '...' ? onChangePage(link.label) : {}">
          <button>{{link.label}}</button>
        </li>
        <li v-if="eventLogs.currentPage < eventLogs.lastPage">
          <button @click="onChangePage(eventLogs.currentPage + 1)">
            <ChevronRightIcon/>
          </button>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
// import CheckIcon from '@/components/icons/CheckIcon.vue';
// import XIcon from '@/components/icons/XIcon.vue';
// import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
// import ChevronRightIcon from '@/components/icons/chevronRightIcon.vue';
// import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue';
import Icon from '@/components/Icon.vue';
// import Modal from '@/components/Modal.vue';
import TimezoneMixin from '@/mixins/TimezoneMixin';

const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};

export default {
  name: 'TableUser',
  components: {
    // CheckIcon, XIcon, ChevronRightIcon, ChevronLeftIcon, Icon,
    Icon,
  },
  mixins: [TimezoneMixin],
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    eventLogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isModalOpen: false,
      selectedIndex: -1,
      edit: {
        user: {
          name: '',
        },
      },
      links: [],
    };
  },
  methods: {
    toggleRow(i) {
      this.isOpen = !this.isOpen;
      this.selectedIndex = i;
    },
    onChangePage(page) {
      this.$emit('onChangePage', page);
    },
    openModal(id) {
      this.$emit('showInfo', id);
    },
  },
  created() {
    this.links = this.eventLogs.links;
  },
};
</script>
<style scoped>

  a, a:hover {
      color: #0a58ca !important;
  }
  .rowTable {
    margin-top: 20px !important;
  }
  .isProcessing {
    @apply bg-blue-700 text-white px-6 py-2 rounded-full;
  }
  .isPendent {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .delete {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .edit {
    @apply bg-blue-800 text-white px-6 py-2 rounded-full;
  }
  .isSuccess {
    @apply bg-green-500 text-white px-6 py-2 rounded-full;
  }
  .headerTable {
    background-color: var(--light-gray);
    padding: 1rem !important;
  }
  .th-Row {
    @apply text-sm;
    padding: 0.5rem !important;
    margin-bottom: 1rem;
    font-family: 'FreeSans';
  }
  .td-Row {
    @apply border-t border-gray-200 text-sm;
    padding: 1.5rem !important;
    font-family: 'FreeSans';
  }
  .badge {
    @apply text-sm px-2 font-medium text-white rounded;
    background-color: #0857A3;
  }
  .active {
    color: green;
  }
  .inactive {
    color: var(--primary-red)
  }
  .toggleRow {
    @apply transform rotate-180;
  }

.pagination {
  @apply flex justify-between
}

.pagination li {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
  cursor: pointer;
}

.pagination li.active {
  background-color: #0857A3;
  color: white;
  border: 1px solid #0857A3;
  cursor: pointer;

}

.scroll {
  max-height: 45vh;
  overflow: auto;
  position:relative;
  z-index:1;
}

.inner-element{
   z-index: 2;
}

.ul-custom {
      padding-left: 2rem !important;
}
</style>
