<template>
  <div>
    <div  v-if="packages.last_page > 1" class="w-2/12">
      <ul  class="pagination">
        <li v-if="packages.current_page > 1">
          <button @click="onChangePage(packages.current_page - 1)">
             <ChevronLeftIcon/>
          </button>
        </li>
        <li
          v-for="(link, i) in packages.links"
          :key="i"
          :class="{'active': link.label == packages.current_page}"
          @click="link.label != '...' ? onChangePage(link.label) : {}">
          <button>{{link.label}}</button>
        </li>
        <li v-if="packages.current_page < packages.last_page">
          <button @click="onChangePage(packages.current_page + 1)">
            <ChevronRightIcon/>
          </button>
        </li>
      </ul>
    </div>
    <table class="w-full">
      <thead>
        <tr class="w-full headerTable text-center">
          <th class="th-Row">No.</th>
          <th class="th-Row">GVT</th>
          <th class="th-Row">Cliente</th>
          <th class="th-Row">Estado</th>
          <th class="th-Row">Pago</th>
          <th class="th-Row">Factura</th>
          <th class="th-Row">Editar</th>
          <!-- <th class="th-Row">Borrar</th> -->
          <th class="th-Row">Detalle</th>
        </tr>
      </thead>
      <tbody>
        <template class="my-4" v-for="(item, i) in packages.packages">
          <tr class="rowTable my-2 text-center" v-bind:key="item.id" >
            <td class="td-Row">{{item.id}}</td>
            <td class="td-Row">GVT{{item.id}}</td>
            <td class="td-Row">{{item.client}}</td>
            <td class="td-Row">
              <select-input
                idInput="sortBySelect"
                idFormGroup="sort-group"
                v-model="item.status_id"
                :options="statusList"
                :valueField="'id'"
                :textField="'display_name'"
              />
            </td>
            <td class="td-Row">
              <button
                :class="{
                  isPendent: item.state === 'Procesando'
                }">
                {{item.payment}}
              </button>
            </td>
            <td class="td-Row">
              <button
                  class="px-2 py-2 text-white self-center rounded bg-gray-300">
                <icon class="text-center m-auto" name="DocumentTextIcon" />
              </button>
            </td>
            <td class="td-Row relative">
                <button
                  @click="() => $router.push({
                    name: 'Editar Paquete',
                    params: { id: item.id }
                  })"
                  class="px-2 py-2 text-white self-center rounded bg-blue-600">
                <icon class="text-center m-auto" name="EditIcon" />
              </button>
            </td>
              <!-- <td class="td-Row relative">
                <button
                  class="px-2 py-2 text-white self-center rounded bg-red-600">
                <icon class="text-center m-auto" name="TrashIcon" />
              </button>
            </td> -->
            <td class="td-Row">
              <chevron-down-icon
              :class="{toggleRow: isOpen && selectedIndex === i}"
              @click.native="toggleRow(i)" />
            </td>
          </tr>
          <tr
            class="rowTable my-2 text-center relative"
            v-bind:key="`insideRow${item.id}`"
            v-if="isOpen && selectedIndex === i">
            <td colspan="10">
               <table class="w-full  h-8">
              <thead>
                <tr class="w-full headerTable text-center">
                  <th scope="th-Row">No.</th>
                  <th scope="th-Row">Descripción</th>
                  <th scope="th-Row">Valor Declarado</th>
                  <th scope="th-Row">Tracking</th>
                  <th scope="th-Row">AWB</th>
                  <th scope="th-Row">Creado</th>
                  <th scope="th-Row">Actualizado</th>
                  <th scope="th-Row">Ruta</th>
                  <th scope="th-Row">Sucursal</th>
                  <th scope="th-Row">Inventario</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{i+1}}</th>
                  <td class="td-Row">{{item.description}}</td>
                  <td class="td-Row">${{item.price}}</td>
                  <td class="td-Row">{{item.tracking}}</td>
                  <td class="td-Row">N/A</td>
                  <td class="td-Row">{{ formatDateTime(item.created_at) }}</td>
                  <td class="td-Row">{{ formatDateTime(item.updated_at) }}</td>
                  <td class="td-Row">N/A</td>
                  <td class="td-Row">N/A</td>
                  <td class="td-Row">N/A</td>
                </tr>
                <tr>
                  <td colspan="10">
                    <router-link
                      class="no-underline"
                      :to="{ name: 'Detalle del paquete', params: { id: item.id } }">
                      Más detalles...
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div  v-if="packages.last_page > 1" class="w-2/12 mt-2">
      <ul  class="pagination">
        <li v-if="packages.current_page > 1">
          <button @click="onChangePage(packages.current_page - 1)">
             <ChevronLeftIcon/>
          </button>
        </li>
        <li
          v-for="(link, i) in packages.links"
          :key="i"
          :class="{'active': link.label == packages.current_page}"
          @click="link.label != '...' ? onChangePage(link.label) : {}">
          <button>{{link.label}}</button>
        </li>
        <li v-if="packages.current_page < packages.last_page">
          <button @click="onChangePage(packages.current_page + 1)">
            <ChevronRightIcon/>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronRightIcon from '@/components/icons/chevronRightIcon.vue';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue';
import SelectInput from '@/components/SelectInput.vue';
import StatusMixin from '@/mixins/StatusMixin';
import PackageMixin from '@/mixins/PackageMixin';
import TimezoneMixin from '@/mixins/TimezoneMixin';

const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};

export default {
  name: 'TablePackage',
  components: {
    Icon, SelectInput, ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon,
  },
  mixins: [StatusMixin, PackageMixin, TimezoneMixin],
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    packages: {
      type: Object,
      required: true,
    },
    statusList: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      rowSelected: -1,
      selectedIndex: -1,
      state: '',
    };
  },
  methods: {
    toggleRow(i) {
      this.isOpen = !this.isOpen;
      this.selectedIndex = i;
    },
    onChangePage(page) {
      this.$emit('onChangePage', page);
    },
  },
};
</script>
<style scoped>
  .rowTable {
    margin-top: 20px !important;
  }
  .isProcessing {
    @apply bg-blue-700 text-white px-6 py-2 rounded-full;
  }
  .isPendent {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .delete {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .edit {
    @apply bg-blue-800 text-white px-6 py-2 rounded-full;
  }
  .isSuccess {
    @apply bg-green-500 text-white px-6 py-2 rounded-full;
  }
  .headerTable {
    background-color: var(--light-gray);
    padding: 1rem !important;
  }
  .th-Row {
    @apply text-sm;
    padding: 0.5rem !important;
    margin-bottom: 1rem;
    font-family: 'FreeSans';
  }
  .td-Row {
    @apply border-t border-gray-200 text-sm;
    padding: 1.5rem !important;
    font-family: 'FreeSans';
  }
  ol, ul {
      padding-left: 0rem !important;
  }
  .toggleRow {
    @apply transform rotate-180;
  }

  .pagination {
  @apply flex justify-between
}

.pagination li {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
  cursor: pointer;
}

.pagination li.active {
  background-color: #0857A3;
  color: white;
  border: 1px solid #0857A3;
  cursor: pointer;
}

</style>
