<template>
  <div class="w-full flex flex-col">
    <h3 class="text-start mt-4 text-2xl mb-2">Validación</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Listado</h4>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-3 gap-4 mt-4" v-if="isToggle">
              <input-field
                idInput="keyWord"
                idFormGroup="keyWord-group"
                type="text"
                label="GVT"
                v-model="filterForm.keyWord"
              />
              <select-input
              idInput="categorySelect"
              idFormGroup="category-group"
              label="Category"
              v-model="filterForm.category"
              :options="categoryListOptions"
              />
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-start">
        <label class="mb-4">Validación</label>
      </div>
      <table-package-validation />
    </section>
  </div>
</template>

<script>
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TablePackageValidation from '../../components/TablePackageValidation.vue';

export default {
  name: 'ClearPackage',
  components: {
    PlusIcon, MinusIcon, InputField, SelectInput, TablePackageValidation,
  },
  data() {
    return {
      isToggle: true,
      filterForm: {
        keyWord: '',
        category: null,
      },

      categoryListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'A', value: 'a' },
        { text: 'B', value: 'b' },
        { text: 'C', value: 'c' },
        { text: 'D', value: 'd' },
        { text: 'E', value: 'e' },
      ],

    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
  },
};
</script>

<style scoped>
</style>
