import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('packages');

export default {
  computed: mapState({
    payments: (state) => state.payments,
    payment: (state) => state.payment,
    statuses: (state) => state.statuses,
  }),
  methods: mapActions([
    'loadPayments',
    'loadStatuses',
    'setPayment',
  ]),
};
